import React from "react";
import Layout from "../components/layout";
import styles from "../styles/pages.module.css";

export default function Contact() {
	return (
		<Layout>
			<p>I won't put any terms. But don't do anything illegal as I won't take the responsibility and you would be solely responsible for your wrong doing.</p>
		</Layout>
	)
}